@layer tailwind-base, primeng, tailwind-utilities;
        
@layer tailwind-base {
    @tailwind base;
}

@layer primeng;

@layer tailwind-utilities {
  
  @tailwind components;
  @tailwind utilities;
}

body, html {
  font-size: 14px;
}

.p-dropdown-items,
.p-inputtext {
  font-size: 0.85rem;
  padding: 6px;
}

.p-dropdown,
.p-inputtextarea,
.p-calendar,
.p-inputtext {
  border-radius: 10px;
}

.p-dropdown {
  padding: 2px;
}

.p-float-label {
  font-size: 0.85rem;
}

textarea.p-inputtextarea,
.p-autocomplete input {
  width: 100%;
}

.p-filled {
  background-color: #f8fafc;
}